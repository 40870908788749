.spinner {
  padding: 1em;
  display: flex;
  justify-content: center;
  margin: 2.5rem;
}

.spinning {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
